/* don't remove this */
.App {
  display: flex;
  width: 100%;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  opacity: 0.5;
  background-color: #d7dae2;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #c4c8d8;
}

@font-face {
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Regular.otf");
}

@font-face {
  font-family: "Proxima Nova Black";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Black.otf");
}

@font-face {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Bold.otf");
}

@font-face {
  font-family: "Proxima Nova Extrabold";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Extrabold.otf");
}

@font-face {
  font-family: "Proxima Nova Light";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Light.otf");
}

@font-face {
  font-family: "Proxima Nova Semibold";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Semibold.otf");
}

@font-face {
  font-family: "Proxima Nova Thin";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Thin.otf");
}

/* antd styles which get rendered outside root container */
.ant-tooltip-inner,
.ant-tooltip-arrow::before {
  background-color: #585c5f !important;
  font-size: 12px;
  white-space: pre-line;
}

.custom-select .number-info {
  color: #588fdb;
}

.custom-select .number-task {
  color: #eaa84f;
}

.custom-select .ant-select-dropdown-menu-item {
  font-family: "Proxima Nova Regular";
  color: #072439;
}

.custom-select .ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item:hover,
.ant-select-dropdown-menu-item:focus,
.ant-select-dropdown-menu-item:active {
  color: #2d885a;
  font-family: "Proxima Nova Bold";
  background-color: #f1f5f3;
}

/* Feedback styling */
.ant-message-notice-content {
  padding: 0 !important;
}

.ant-message-notice-content .ant-message-custom-content {
  border-radius: 0.25rem;
  padding: 0.625rem 1rem;
}

.ant-message-notice-content .ant-message-error {
  background: #f8d7da;
  color: #721c24;
  border: solid 1px #f5c6cb;
}

.ant-message-error .anticon {
  color: #721c24 !important;
}

.ant-message-notice-content .ant-message-success {
  background: #d4edda;
  color: #155724;
  border: solid 1px #c3e6cb;
}

.ant-message-success .anticon {
  color: #155724 !important;
}

.ant-message-notice-content .ant-message-info {
  background: #d1ecf1;
  color: #0c5460;
  border: solid 1px #bee5eb;
}

.ant-message-info .anticon {
  color: #0c5460 !important;
}

.ant-popover-inner-content {
  max-height: 60vh;
  overflow-y: auto;
}
